'use client';

import { useEffect, useState } from 'react';
import { hasCookie, setCookie } from 'cookies-next';

const CookieConsent = () => {
  const [firstRender, setFirstRender] = useState(false);
  const [showConsent, setShowConsent] = useState(true);

  const oneMonth = 60 * 60 * 24 * 30;

  useEffect(() => {
    setShowConsent(hasCookie('localConsent'));
    setTimeout(() => setFirstRender(true), 500);
  }, []);

  const rejectCookie = () => {
    setShowConsent(true);
    setCookie('localConsent', 'false', { maxAge: oneMonth });
  };

  const acceptCookie = () => {
    setShowConsent(true);
    setCookie('localConsent', 'true', { maxAge: oneMonth });
  };

  if (showConsent) {
    return null;
  }

  return (
    <div
      className="fixed bottom-1 inset-x-1 md:bottom-4 md:inset-x-10 flex flex-col gap-5 lg:gap-8 lg:flex-row
        justify-between bg-white border-2 border-green-30 px-8 py-5 shadow-green-30 border-b-8
        rounded-3xl text-blue-100
        transition-all duration-500 z-50"
      style={{ transform: firstRender ? 'translateY(0%)' : 'translateY(100%)' }}
    >
      <div className="flex-grow w-full">
        <h2 className="text-base sm:text-xl mb-2">We use cookies to improve your user experience</h2>
        <p className="text-xs sm:text-base">
          By clicking &quot;Allow cookies&quot; you agree to the storing
          of cookies on your device to
          enhance site navigation and analyse site usage.
          Clicking &quot;Reject&quot; means you do not agree to our use of non-essential
          cookies on your device.
        </p>
      </div>
      <div className=" flex-shrink-0 flex items-center gap-4 min-w-max">
        <button
          type="button"
          className="w-full font-bold flex-grow px-4 sm:px-8 py-2 sm:py-4 text-xs md:text-lg border-2 border-green-100  rounded-full"
          onClick={rejectCookie}
        >
          Reject
        </button>
        <button
          type="button"
          className="w-full font-bold whitespace-nowrap flex-grow px-4 sm:px-8 py-2 sm:py-4 text-xs md:text-lg border-2 border-green-100 bg-green-100 rounded-full"
          onClick={acceptCookie}
        >
          Allow cookies
        </button>
      </div>
    </div>
  );
};

export default CookieConsent;
